<template>
  <main v-if='!loading' role='main' class='page-content'>
    <div class='subheader hidden-lg-up d-flex'>
      <portal to='ariane'>
        <h1 class='subheader-title mb-2 text-info'>
          <i class='fal fa-poll m-2 text-info'></i> Choisissez le formulaire
        </h1>
      </portal>
    </div>
    <div class='d-flex justify-content-center flex-wrap'>
      <section v-for='type in types' :key='type.id' class='m-2' :class='types.length > 1 ? "w-25" : "w-50"'>
        <div class='bg-white shadow sm:rounded-lg'>
          <div class='px-4 py-5 sm:px-6'>
            <h2 class='text-lg leading-6 font-medium text-blue-500'>
              {{ type.name }}
            </h2>
          </div>
          <div class='border-t border-gray-200 px-4 py-5 sm:px-6'>
            <ul>
              <li class='mb-4 d-flex flex-wrap justify-content-between' v-for='form in forms' :key='form.id'>
                <router-link v-if='form.form_type_id === type.id' class='d-flex w-50'
                             :to='"/post/create/forms/" + form.id'>
                  <i class='fas fa-circle align-self-center mr-2' style='font-size: xx-small'></i>
                  <div>
                    {{ form.name }}
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'FormChoice',
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapState('form', {
      forms: state => state.forms,
      config: state => state.formsConfig,
      types: state => state.types
    })
  },
  methods: {
    ...mapActions('form', [
      'getForms'
    ])
  },
  created() {
    this.config.filters['active'] = true
    this.getForms().then(() => {
      if (this.forms && this.forms.length === 1) {
        this.$router.push(`/post/create/forms/${this.forms[0].id}`)
      }
      this.loading = false
    }).catch((error) => {
      console.log(error)
    })
  }
}
</script>
